import React from 'react'
import one from './1.svg'
import two from './2.svg'
import three from './3.svg'

import five from './5.svg'
import six from './6.svg'
import seven from './7.svg'
import eight from './8.svg'
import nine from './9.svg'
import ten from './10.svg'

import Audi from './auditorium.svg'
export default function Footer() {
    return (

        <div style={{padding:"0rem 2rem",marginTop:'-1rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
       
        <div class="row  fotterPadding" >
        
        <div class="col text-center yellow">
        
        <a  href="https://quest-global.virtuallive.in/lobby.html" >
        
            <img src={one} class="imgLogo " />
            
             
            <p className="menuname">Lobby</p>
        </a>
        </div>


        <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://quest-global.virtuallive.in/futurewalk.html" class="yellow disabled">
          <img src={seven} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> Future @ QuEST</p>
      </a>
      </div>

        <div class="col text-center ">
        
        <a  href="https://quest-global.virtuallive.in/verticalwalk.html"  class=" disabled">
        
          <img src={ten} class="imgLogo" />
         
         <p className="menuname">Vertical Lounge</p>
         </a>     
        </div>
        
        <div class="col text-center  ">
        
        <a href="https://quest-global.virtuallive.in/auditorium.html"
         class=" ">
         <img src={five} 
       
          class="imgLogo disabled" />          
         
        
         <p className="menuname">Auditorium</p>
         </a>
        </div>
       
        
        <div class="col text-center ">
        
          <a id="navIdKnow" onclick="EndTime()"  href="https://quest-global.virtuallive.in/solutionwalk.html" class="yellow disabled">
            <img src={nine} class="imgLogo disabled" /> 
         
     
       
        <p  className="menuname"> Solution Lounge</p>
        </a>
        </div>

        <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://quest-global.virtuallive.in/myquestwalk.html" class="yellow disabled">
          <img src={eight} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> My Quest</p>
      </a>
      </div>

     


      

      <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://quest-global.virtuallive.in/speaker.html" class="yellow disabled">
          <img src={two} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> Speaker</p>
      </a>
      </div>


      <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://quest-global-photobooth.virtuallive.in/" class="yellow disabled">
          <img src={six} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> photo Booth</p>
      </a>
      </div>

        <div class="col text-center ">
        
        <a id="navIdKnow"  href="https://quest-global.virtuallive.in/game.html" class=" disabled">
          <img src={three} class="imgLogo" /> 
              
  
       
   <p className="menuname">Game's Arena</p> 
   </a>
      </div>
        
          </div>
        
        
        
        
        
        </div> 
    )
}
